import { Route } from '@angular/router';

import { DashboardComponent } from '@bookly/admin';
import { LoginComponent, authGuard } from '@bookly/shared';
import { UserDashboardComponent } from '@bookly/user';

export const appRoutes: Route[] = [
  { path: 'login', component: LoginComponent },
  {
    path: 'dashboard',
    component: UserDashboardComponent,
    canActivate: [authGuard],
    loadChildren: () => import('./children.routes').then(r => r.routes),
  },
  {
    path: 'admin',
    component: DashboardComponent,
    canActivate: [authGuard],
    loadChildren: () => import('./admin.routes').then(r => r.routes),
  },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];

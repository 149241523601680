import { OverlayModule } from '@angular/cdk/overlay';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  ApplicationConfig,
  Injectable,
  importProvidersFrom,
  inject,
  isDevMode,
  provideAppInitializer,
  provideEnvironmentInitializer,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
  ScreenTrackingService,
  getAnalytics,
  provideAnalytics,
} from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import {
  RdsIconLoader,
  RdsNativeDateModule,
  RdsToastModule,
  RdsTooltipModule,
  provideRdsTimepicker,
} from '@rds/angular-components';
import {
  CalendarDateFormatter,
  CalendarModule,
  CalendarNativeDateFormatter,
  DateAdapter,
  DateFormatterParams,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { provideLottieOptions } from 'ngx-lottie';

import {
  AuthService,
  CustomIcon,
  authErrorInterceptor,
  authInterceptor,
  environment,
  httpErrorInterceptor,
} from '@bookly/shared';

import { appInitFactory } from './app-init.factory';
import { appRoutes } from './app.routes';

@Injectable()
class CustomDateFormatter extends CalendarNativeDateFormatter {
  public override dayViewHour({ date }: DateFormatterParams): string {
    return new Intl.DateTimeFormat('de', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(date);
  }
}
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withComponentInputBinding()
      // ToDo Turn on view transitions after admin panel will be mobile responsive
      // withViewTransitions()
    ),
    importProvidersFrom(
      BrowserModule,
      RdsToastModule,
      RdsTooltipModule,
      OverlayModule,
      RdsNativeDateModule,
      CalendarModule.forRoot(
        {
          provide: DateAdapter,
          useFactory: adapterFactory,
        },
        {
          dateFormatter: {
            provide: CalendarDateFormatter,
            useClass: CustomDateFormatter,
          },
        }
      )
    ),
    provideAppInitializer(() => {
      const initializerFn = appInitFactory(inject(AuthService));
      return initializerFn();
    }),

    provideEnvironmentInitializer(() => {
      const iconLoader = inject<RdsIconLoader<CustomIcon>>(RdsIconLoader);
      iconLoader.addCustomImage('catering', '/assets/catering.svg');
      iconLoader.addCustomImage('furniture', '/assets/furniture.svg');
    }),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        authErrorInterceptor,
        httpErrorInterceptor,
      ])
    ),
    provideRdsTimepicker(),
    provideAnimations(),
    provideLottieOptions({ player: () => import('lottie-web') }),
    provideExperimentalZonelessChangeDetection(),
    provideServiceWorker('combined-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
    provideMessaging(() => getMessaging()),
  ],
};

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  RdsButtonModule,
  RdsDialogModule,
  RdsIconComponent,
  RdsProgressSpinnerModule,
} from '@rds/angular-components';

import { NotificationComponent } from '../../components/notification/notification.component';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-all-notifications-dialog',
  standalone: true,
  imports: [
    RdsDialogModule,
    RdsButtonModule,
    RdsIconComponent,
    RdsProgressSpinnerModule,
    NotificationComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './all-notifications-dialog.component.html',
})
export class AllNotificationsDialogComponent {
  readonly #notificationsService = inject(NotificationsService);
  readonly latestNotifications = this.#notificationsService.allNotifications;
  readonly isLoading = this.#notificationsService.isLoading;
}

import { OverlayModule } from '@angular/cdk/overlay';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  Injectable,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import {
  RdsNativeDateModule,
  RdsToastModule,
  RdsTooltipModule,
} from '@rds/angular-components';
import {
  CalendarDateFormatter,
  CalendarModule,
  CalendarNativeDateFormatter,
  DateAdapter,
  DateFormatterParams,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { provideLottieOptions } from 'ngx-lottie';

import { appInitFactory } from './app-init.factory';
import { appRoutes } from './app.routes';
import { authInterceptor } from './auth/auth.interceptor';
import { AuthService } from './auth/auth.service';
import { authErrorInterceptor } from './core/interceptors/auth-error.interceptor';
import { httpErrorInterceptor } from './core/interceptors/http-error.interceptor';

@Injectable()
class CustomDateFormatter extends CalendarNativeDateFormatter {
  public override dayViewHour({ date }: DateFormatterParams): string {
    return new Intl.DateTimeFormat('de', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(date);
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, withComponentInputBinding()),
    importProvidersFrom(
      BrowserModule,
      RdsToastModule,
      RdsTooltipModule,
      OverlayModule,
      RdsNativeDateModule,
      CalendarModule.forRoot(
        {
          provide: DateAdapter,
          useFactory: adapterFactory,
        },
        {
          dateFormatter: {
            provide: CalendarDateFormatter,
            useClass: CustomDateFormatter,
          },
        }
      )
    ),
    {
      provide: APP_INITIALIZER,
      deps: [AuthService],
      useFactory: appInitFactory,
      multi: true,
    },
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        authErrorInterceptor,
        httpErrorInterceptor,
      ])
    ),
    provideAnimations(),
    provideLottieOptions({ player: () => import('lottie-web') }),
    provideExperimentalZonelessChangeDetection(),
  ],
};

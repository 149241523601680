import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { interval, switchMap } from 'rxjs';

import { NotificationsService, PushNotificationsService } from '@bookly/shared';

@Component({
  imports: [RouterOutlet],
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  readonly #notificationService = inject(NotificationsService);
  readonly #pushNotificationService = inject(PushNotificationsService);

  ngOnInit() {
    if ('Notification' in window && Notification.permission === 'granted') {
      this.#pushNotificationService.message$
        .pipe(switchMap(() => this.#notificationService.updateNotifications()))
        .subscribe();

      return;
    }

    interval(30000)
      .pipe(switchMap(() => this.#notificationService.updateNotifications()))
      .subscribe();
  }
}
